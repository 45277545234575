<template>
  <div>
    <Toolbar
      :handle-reset="resetForm"
      :handle-submit="onSendForm"
    />
    <TenantAccountSettingForm
      :errors="violations"
      :values="item"
      refForm="updateForm"
      ref="updateForm"
      v-if="item"
    />
    <Loading :visible="isLoading" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import { mapFields } from "vuex-map-fields";
  import TenantAccountSettingForm from "../../components/tenantAccountSetting/Form.vue";
  import Loading from "@/components/Loading";
  import Toolbar from "@/components/Toolbar";
  import UpdateMixin from "../../mixins/UpdateMixin";

  const servicePrefix = "TenantAccountSetting";

  export default {
    name: "TenantAccountSettingUpdate",
    servicePrefix,
    mixins: [UpdateMixin],
    data() {
      return {
        item: {
          invoiceNumberRange: {},
          creditNumberRange: {},
          offerNumberRange: {},
          orderConfirmationNumberRange: {},
          customerNumberRange: {},
          paymentMethods: [],
          dunningLevels: [],
        },
        updateMessage: this.$t("tenantAccountSettingUpdateSuccess"),
      };
    },
    components: {
      Loading,
      Toolbar,
      TenantAccountSettingForm,
    },
    computed: {
      ...mapFields("tenantAccountSetting", {
        isLoading: "isLoading",
        error: "error",
        updated: "updated",
        violations: "violations",
      }),
      ...mapGetters("tenantAccountSetting", ["find"]),
    },
    methods: {
      ...mapActions("tenantAccountSetting", {
        retrieve: "load",
        update: "update",
      }),
    },
  };
</script>
